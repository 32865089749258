<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="750">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">选择试卷</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="10" offset="1">
                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="datas"
                            item-key="topic_id"
                            :items-per-page="10"
                            :items-per-page-options="[5, 10, 15, -1]"
                            class="elevation-2 row-pointer"
                            :single-select="singleSelect"
                            :hide-default-footer="datas.length <= 10"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageText: '每页行数',
                                pageText: '',
                                'items-per-page-all-text': '全部',
                            }"
                            @click:row="selectRow"
                        >
                            <template v-slot:item.name="{ item }">
                                <div
                                    class="font-weight-medium"
                                >
                                    {{ item.name }}
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer class="mb-4"></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getPapers } from "@/api/admin_exam";
import { getTypeColor } from '@/utils/common';

export default {
    name: "SelectPaperDlg",

    data: function () {
        return {
            dialog: false,

            headers: [
                { text: "试卷名称", value: "name", align: "start" },
                { text: "类型", value: "type_text", align: "center" },
                { text: "级别", value: "level_text", align: "center" },
            ],
            datas: [],

            singleSelect: true,
            selected: [],
            
            type: '',
            groupId: '',
            
        };
    },
    methods: {
        getTypeColor,
        loadData() {
            getPapers().then((response) => {
                // console.log(response.data)
                this.datas = response.data
                
            }).catch(function (err) {
                console.log(err);
            });
        },
        openDlg(selected = []) {
            this.selected = selected
            this.dialog = true

            this.loadData();
        },
        selectRow(row) {
            // console.log(row)
            this.$emit("submit", {
                paperId: row.paper_id,
                paperName: row.name,
            })
            this.dialog = false
        }
    },
    components: {},
};
</script>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
