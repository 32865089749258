<template>
    <v-container fluid class="px-8" >
        <v-row>
            <v-col class="d-flex" >
                <v-btn
                    tile
                    dark
                    color="cyan darken-1"
                    class="font-weight-bold text-body-1 mb-6"
                    @click.stop="openEditExamDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    创建测评
                </v-btn>
            </v-col>
        </v-row>

        <!-- 课程列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
            @click:row="clickRow"
        >
            <template v-slot:no-data>
                暂无测评
            </template>
            <template v-slot:item.name="{ item }">
                <div class="td-line font-weight-medium">
                    <div class="active-hover">
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.exam_start_time="{ item }">
                {{ item.exam_start_time.substring(0, 16) }}
            </template>
            <template v-slot:item.fee="{ item }">
                {{ item.fee > 0 ? item.fee : '免费' }}
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="purple"
                            @click.stop="openEditExamDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑试卷信息</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.exam_id)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除试卷</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- 创建/编辑测评 -->
        <EditExamDlg ref="editExamDlg" @submit="submitExam" @select="selectPaper" />
        <!-- 在班级中关联课程包 -->
        <SelectPaperDlg ref="selectPaperDlg" @submit="submitSelectPaper" />

        <ConfirmDlg
            ref="delExamConfirmDlg"
            title="删除测评"
            text="确认 删除 当前测评么？"
            keyword=" 删除 "
            @confirm="delExamConfirm"
        />

    </v-container>
</template>

<script>
import EditExamDlg from '@/views/components/dialog/EditExamDlg';
import SelectPaperDlg from '@/views/components/dialog/SelectPaperDlg';
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';

import { getExaminations, editExamination, delExamination } from "@/api/admin_exam";
import { getTypeColor } from '@/utils/common';
import { getTypeFirstLetter } from "@/utils/util";

export default {
    name: "ExamList",
    data() {
        return {
            headers: [
                { text: "测评名称", value: "name", align: "left", sortable: false, },
                { text: "类别", value: "type_text", align: "center", sortable: false, },
                { text: "级别", value: "level_text", align: "center", sortable: false, },
                { text: "开始时间", value: "exam_start_time", align: "center", sortable: false, },
                { text: "时长（分）", value: "duration", align: "center", sortable: false, },
                { text: "费用（元）", value: "fee", align: "center", sortable: false, },
                // { text: "状态", value: "status_text", align: "center", sortable: false, },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 120},
            ],
            datas: [],
        };
    },
    mounted() {
    },
    activated() {
        this.loadData();
    },
    methods: {
        getTypeColor,
        getTypeFirstLetter,
        loadData() {
            getExaminations().then((response) => {
                // console.log(response.data);
                if (response.data) {
                    this.datas = response.data;
                }
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id) {
            this.$router.push({path: '/exam-mgr/exam-list/exam', query: {id: id}});
        },
        clickRow(item) {
            this.goto(item.exam_id);
        },

        openEditExamDlg(data = null) {
            this.$refs.editExamDlg.openDlg(data);
        },
        openConfirmDlg(examId) {
            this.$refs.delExamConfirmDlg.openDlg(examId);
        },

        delExamConfirm(examId) {
            delExamination(examId).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].exam_id == examId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除测评成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除测评失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        submitExam(exam) {
            editExamination(exam).then((response) => {
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "编辑测评成功"});

                    // 更新列表数据
                    let newData = response.data
                    if (exam.exam_id == '') {
                        this.datas.unshift(newData);
                    } else {
                        for (let i in this.datas) {
                            if (this.datas[i].exam_id == exam.exam_id) {
                                this.datas[i] = newData
                                this.$set(this.datas, i, this.datas[i]);
                                break
                            }
                        }
                    }

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "编辑测评失败"});
                }
            }).catch(function (err) {
                console.log(err);
            });
        },

        selectPaper() {
            this.$refs.selectPaperDlg.openDlg();
        },
        submitSelectPaper(selected) {
            this.$refs.editExamDlg.setSelected(selected)
        }
    },
    components: {
        EditExamDlg,
        SelectPaperDlg,
        ConfirmDlg,
    }
};
</script>

<style lang="scss" scoped>
.option-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .option-item {
        width: 150px;
    }
}
.btn-font {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.td-line {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            background-color: #fff;
            border-radius: 50px;
        }
    }
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
