<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="600">
            <v-card class="px-4 pt-3">
                <v-card-title class="">
                    <span class="text-h4 font-weight-bold">测评信息</span>
                </v-card-title>
                <div class="form-content mx-12">
                    <v-form v-model="valid" ref="form" class="">
                        <div class="form-row">
                            <v-text-field
                                label="测评名称"
                                v-model="name"
                                required
                                :rules="[v => !!v]"
                            ></v-text-field>
                        </div>
                        <div class="form-row">
                            <div class="col-one">
                                <v-select
                                    v-model="type"
                                    :items="typeMenu"
                                    label="类型"
                                    required
                                    :rules="[v => !!v]"
                                ></v-select>
                            </div>
                            <div class="col-two">
                                <v-select
                                    v-model="level"
                                    :items="levelMenu"
                                    label="级别"
                                    required
                                    :rules="[v => !!v]"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-one middle">
                                <div class="form-label label mr-4">测评日期</div>
                                <v-menu
                                    v-model="menuStartDate"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        label="日期"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[v => !!v]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        :day-format="getDay"
                                        v-model="date"
                                        no-title
                                        @input="menuStartDate = false"
                                    ></v-date-picker>
                                </v-menu>
                            </div>
                            <div class="col-two middle">
                                <div class="form-label label mr-4">开始时间</div>
                                <v-select
                                    class="mr-4"
                                    v-model="hour"
                                    :items="hourMenu"
                                    label="时"
                                    dense
                                    :rules="[v => !!v]"
                                ></v-select>
                                <!-- <div class="form-label mr-4">点</div> -->
                                <v-select
                                    v-model="min"
                                    :items="minMenu"
                                    label="分"
                                    dense
                                    :rules="[v => !!v]"
                                ></v-select>
                                <!-- <div class="form-label">分</div> -->
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-one middle">
                                <div class="form-label label mr-4">测评时长</div>
                                <v-text-field
                                    label="（分钟）"
                                    v-model.number="duration"
                                    required
                                    :rules="[v => !!v]"
                                ></v-text-field>
                            </div>
                            <div class="col-two"></div>
                        </div>
                        <div class="form-row start" style="height: 70px">
                            <div class="col-one middle">
                                <div class="form-label mr-4">是否收费</div>
                                <v-switch
                                    v-model="is_charge"
                                    inset
                                    dense
                                    class="mr-8"
                                ></v-switch>
                            </div>
                            <div class="col-two">
                                <v-text-field label="测评价格（元）" 
                                    class="mr-0"
                                    v-model="fee"
                                    required
                                    :rules="[v => !!v]"
                                    v-if="is_charge"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-row start" style="height: 60px">
                            <div class="form-label mr-4">关联试卷</div>
                            <div v-if="!paper_id || paper_id == ''">
                                <v-btn
                                    outlined
                                    rounded
                                    color="purple"
                                    @click.stop="select"
                                >
                                    <v-icon left> mdi-plus </v-icon>
                                    选择试卷
                                </v-btn>
                            </div>
                            <div class="form-content" v-else>
                                {{ paper_name }}
                                <v-icon 
                                    dark
                                    color="grey"  
                                    class="ml-2"
                                    @click.stop="clearCurPaper()"
                                >
                                    mdi-close-circle
                                </v-icon>
                            </div>
                        </div>
                    </v-form>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: "EditExamDlg",

    mixins: [validationMixin],
    validations: {
        name: { required },
        type: { required },
        level: { required },
    },

    data: function () {
        return {
            dialog: false,

            // 数据
            exam_id: '',
            name: '',
            type: 0,
            // type_text: '',
            level: 0,
            // level_text: '',
            levelMenu: [
                { text: '一级', value: 1 },
                { text: '二级', value: 2 },
                { text: '三级', value: 3 },
                { text: '四级', value: 4 },
                { text: '五级', value: 5 },
                { text: '六级', value: 6 },
            ],
            typeMenu: [
                { text: 'Scratch', value: 1 },
                { text: 'Python',  value: 2 },
                { text: 'C/C++',   value: 3 },
                { text: 'Blockly', value: 4 },
                { text: 'EV3', value: 5 },
                { text: 'Scratch硬件', value: 6 },
                { text: 'Python硬件',  value: 7 },
            ],
            duration: 0,
            fee: 0,
            is_charge: false,
            exam_start_time: '',
            menuStartDate: false,
            paper_id: '',
            paper_name: '',

            date: '',
            hour: '',
            min: '',
            hourMenu: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
            minMenu: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20',
                           '21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40',
                           '41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'],
            valid: true,
        };
    },
    computed: {
    },
    created() {},
    activated() {},
    methods: {
        getDay(date) {
            return new Date(date).getDate()
        },
        getDate(date) {
            if (date != "") {
                return date.substr(0, 10)
            }
            return ""
        },
        getToday() {
            return new Date().toISOString().substr(0, 10)
        },
        openDlg(data = null) {
            // refs.form.reset需要在对话框初始化完成后调用，所以先设置为true
            this.dialog = true

            if (data) {
                this.exam_id = data.exam_id
                this.name = data.name
                this.type = data.type
                this.level = data.level
                this.duration = data.duration
                this.exam_start_time = data.exam_start_time
                this.paper_id = data.paper_id
                this.paper_name = data.paper_name
                this.fee = data.fee
                if (this.fee > 0) {
                    this.is_charge = true
                } else {
                    this.is_charge = false
                }

                this.date = data.exam_start_time.substring(0, 10)
                this.hour = data.exam_start_time.substring(11, 13)
                this.min = data.exam_start_time.substring(14, 16)

            } else {
                this.clear()
            }
        },
        clear() {
            this.$nextTick(() => {
                this.$refs.form.reset()
            })

            this.exam_id = ''
            this.name = ''
            this.type = 0
            this.level = 0
            this.duration = 0
            this.exam_start_time = ''
            this.paper_id = ''
            this.paper_name = ''
            this.fee = 0
            this.is_charge = false
            this.date = ''
            this.hour = ''
            this.min = ''
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            if (this.$refs.form.validate()) {
                // 用户确认后调用submit回调，将新的参数传递出去
                let exam_start_time = this.date + ' ' + this.hour + ':' + this.min + ':00'
                this.$emit("submit", {
                    exam_id: this.exam_id,
                    name: this.name,
                    type: this.type,
                    level: this.level,
                    duration: this.duration,
                    exam_start_time: exam_start_time,
                    paper_id: this.paper_id,
                    fee: this.is_charge ? this.fee : 0,
                })
                this.dialog = false
            }
        },
        clearCurPaper() {
            this.paper_id = ''
            this.paper_name = ''
        },
        select() {
            this.$emit("select")
        },
        setSelected(selected) {
            this.paper_id = selected.paperId
            this.paper_name = selected.paperName
        },
    },
    components: {
    },
};
</script>

<style lang="scss" scoped>
.form-row {
    width: 100%;
    display: flex;
    align-items: center;
    &.between {
        justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
    &.top {
        align-items: flex-start;
    }

    .col-one {
        // width: 380px;
        flex: 1;
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .col-two {
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .option-num {
        font-size: 50px;
        font-weight: bold;
        margin-right: 20px;
    }
    .option-other {
        flex: 1;
    }
    .option-img-wrapper {
        margin-top: 12px;
    }
    .option-img {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .form-label {
        font-size: 14px;
        // color: #bbb;
        font-weight: bold;
        &.label {
            min-width: 56px;
        }
    }
    .form-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
.operate-row {
    .value {
        width: 80px;
        max-width: 80px;
        margin-left: 20px;
        margin-right: 12px;
    }
}
.no-scroll {
    ::-webkit-scrollbar {
        width: 0px;
    }
}
</style>
